import React,{MouseEventHandler, useEffect,useState} from 'react';
import { useParams , useNavigate} from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import {Title, Card} from '@tremor/react';
import FullShiftReport from '../components/Shift/FullShiftReport';
import HalfShiftReport from '../components/Shift/HalfShiftReport';
import axios from 'axios';
import { XMarkIcon } from "@heroicons/react/24/outline";
import { calculateTotalHours } from '../lib/utils';
import { Break, Shift as ShiftType,Report } from '../types/shifts';
type ShiftProps = {
    selectedShiftId: string | null,
    closeInspectShift: (() => void) | null
}

const Shift = (props:ShiftProps) => {

    const {
        selectedShiftId,
        closeInspectShift
    } = props

    const {shiftId} = useParams();
    const {isAuthenticated} = useAuth()
    const navigate = useNavigate()
    const [shift, setShift] = useState<ShiftType | null>(null);

    useEffect(() => {
        if(!isAuthenticated){
            navigate('/signin')
        }
    }   , [isAuthenticated,navigate])

    useEffect(() => {
        fetchShift();
    },[]);

    const unlockShift = async (id:string) => {
        try {
          const response = await axios.post(`/shifts/unlock/${id}`);
          if (response.status === 200) {
            if(selectedShiftId){closeInspectShift && closeInspectShift()}else{window.history.back()}
          }
        } catch (err) {
          console.log(err);
        }
      };

    const fetchShift = async () => {    
        try{
            const response = await axios.get(`/shifts/${(!shiftId ? selectedShiftId : shiftId)}`);
            const data = await response.data;
            if(response.status !== 200) {
                throw Error(`Request failed with respone status ${response.status}`);
            }
            setShift(data);
        }catch(err){
            console.log(err);
        }
    }


    const updateShift = async () => {
        console.error("uppdating shifts");
        if(shift){
            try{
                onChange(calculateTotalHours(shift), 'hoursWorkedUI')
                const response = await axios.put(`/shifts/${(!shiftId ? selectedShiftId : shiftId)}`,shift);
                await response.data;
                if(response.status !== 200) {
                    throw Error(`Request failed with respone status ${response.status}`);
                }
                
                //Cleanup / Reset
               /*  onChange('newBreakStart' , "00:00")
                onChange('newBreakEnd',"00:00") */

                // Maybe dont need this
                
                fetchShift()
                if(selectedShiftId){closeInspectShift && closeInspectShift()}else{window.history.back()}
            }catch(err){
                console.log(err);
            }
        }
    }

    const removeBreak = async (breakId:number) => {
        try{
            const response = await axios.delete(`/shifts/breaks/${breakId}`);
            await response.data;
            if(response.status !== 200) {
                throw Error(`Request failed with respone status ${response.status}`);
            }
            fetchShift()
        }catch(err){
            console.log(err);
        }
    }


    const updateBreaks = async (updatedBreaks:Break[]) => {
        if(shift){
            const newShift = {...shift,breaks:updatedBreaks};
            setShift(newShift);
        }
    
    }

    const removeShift = async () => {
        // only delete if manually created.
        try{
            const response = await axios.delete(`/shifts/${(!shiftId ? selectedShiftId : shiftId)}`);
            await response.data;
            if(response.status !== 200) {
                throw Error(`Request failed with respone status ${response.status}`);
            }
            navigate('/app/shifts')
        }catch(err){
            console.log(err);
        }
    }

    const removeAttachment = async (type:string,id:string) => {
        try{
            const response = await axios.delete(`/shifts/attachments/${type}/${(!shiftId ? selectedShiftId : shiftId)}/${id}`);
            await response.data;
            if(response.status !== 200) {
                throw Error(`Request failed with respone status ${response.status}`);
            }
            fetchShift()
        }catch(err){
            console.log(err);
        }
    }

    const createShift = async () => {
        if(shift){

            try{
                onChange( calculateTotalHours(shift) , 'hoursWorkedUI')
                const response = await axios.post(`/shifts`,shift);
                const data = await response.data;
                if(response.status !== 200) {
                    throw Error(`Request failed with respone status ${response.status}`);
                }
                setShift(data);
            }catch(err){
                console.log(err);
            }
        }
    }
    type onChangeType = string | number |boolean | null | undefined;

    const onChange = (value: onChangeType, name: keyof Report) => {
        setShift(prevState => prevState ? ({
          ...prevState,
          [name]: value ?? ""
        }) : null);
      };

    const changeAkare = async (akareNo:number) => {
        try{
            const response = await axios.post(`/shift/change/${(!shiftId ? selectedShiftId : shiftId)}`,{akareNo});
            await response.data;
            if(response.status !== 200) {
                throw Error(`Request failed with respone status ${response.status}`);
            }
            window.history.back();
        }catch(err){
            console.log(err);
        }
    }


    if(shift && !shift.report) return null;
    return (
        <div className="container mx-auto w-[80%] mt-6 ">
            <Card>
                <XMarkIcon className="w-6 h-6 text-gray-500 cursor-pointer mb-6 float-right" onClick={()=>{
                    if(selectedShiftId){closeInspectShift && closeInspectShift()}else{window.history.back()}
                }} />
                {/* <Title>Shift Details</Title> */}
                <Title className="mb-6">{ `${shift && shift.report.name} - Förare ${shift && shift.report.driverNo}`}</Title>

                    {shift &&
                        (shift.report.workshiftNo === '0' || !shift.report.workshiftNo) ?
                        <HalfShiftReport shift={shift} 
                            onChange={onChange} 
                            updateShift={updateShift}
                            createShift={createShift}
                            removeShift={removeShift}
                            removeBreak={removeBreak}
                            removeAttachment={removeAttachment}
                            success={fetchShift}
                        /> : shift &&
                        <FullShiftReport shift={shift} 
                            onChange={onChange} 
                            updateShift={updateShift}
                            createShift={createShift}
                            removeShift={removeShift}
                            removeAttachment={removeAttachment}
                            success={onChange}
                            changeAkare={changeAkare}
                            updateBreaks={updateBreaks}
                            unlockShift={unlockShift}
                        /> 
                    }
            </Card>
        </div>
    );
}

export default Shift;